
  import {mapGetters, mapActions} from 'vuex'
  import {caretForwardOutline } from 'ionicons/icons'
  import { modalController, IonList, IonItemDivider, IonSearchbar } from '@ionic/vue'
  import CataloguePinGroup from '@/plugins/app/country-list/catalogue-pin-group.vue'
  import Functions from '@/plugins/app/filter/utils/functions'

  export default {
    components: {
      IonList, IonItemDivider, IonSearchbar
    },

    mounted() {
      this.fetchCountries()
    },

    data() {
      return {
        query: '',
        activeButton: 0,
        caretForwardOutline
      }
    },

    computed: {
      ...mapGetters('countries', ['countries', 'isLoading']),

      filteredCountries() {
        let countries = this.countries

        // SEARCH BY BUTTONS
        if (this.activeButton == 1) {
          countries = countries.filter(country => {
            return Functions.normalize(country.title[0]).charCodeAt(0) < 103
          })
        } else if ( this.activeButton == 2) {
          countries = countries.filter(country => {
            return (Functions.normalize(country.title[0]).charCodeAt(0) >= 103 &&
                    Functions.normalize(country.title[0]).charCodeAt(0) <= 109)
          })
        } else if ( this.activeButton == 3) {
          countries = countries.filter(country => {
            return (Functions.normalize(country.title[0]).charCodeAt(0) >= 110 &&
                    Functions.normalize(country.title[0]).charCodeAt(0) <= 122)
          })
        }

        return countries
          .filter(country => {
            return Functions.normalize(country.title).includes(Functions.normalize(this.query)) ||
                   Functions.normalize(country.code).includes(Functions.normalize(this.query)) ||
                   Functions.normalize(country.creator).includes(Functions.normalize(this.query))
          })
          .reduce((groups, country) => {
            const firstLetter = Functions.normalize(country.title[0])

            if (!groups[firstLetter])
              return { ...groups, [firstLetter]: [country] }
            return { ...groups, [firstLetter]: [...groups[firstLetter], country] }
          }, {})
      }
    },

    watch: {
      query: function (data) {
        if (data) {
          this.activeButton = 0
        }
      }
    },

    methods: {
      ...mapActions('countries', ['fetchCountries']),

      async openCountry(countryId) {
        this.$router.push(`/app/country-detail/${countryId}`)
      },

      filterButton(buttonId) {
        this.query = ''
        if (this.activeButton == buttonId) {
          this.activeButton = 0
          return
        }
        this.activeButton = buttonId
      }
    }
  }
