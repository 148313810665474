<template>
  <ion-page>
    <ion-header class="ion-padding-horizontal white-background-color">
      <ion-toolbar>
      </ion-toolbar>
      <ion-toolbar>
        <ion-row>
          <ion-col class="ion-no-padding">
            <h1>Countries</h1>
          </ion-col>
        </ion-row>
        <ion-searchbar
          :value="query" @ion-change="query = $event.detail.value"
          class="ion-margin-top ion-no-padding"
          placeholder="Search"
        ></ion-searchbar>
        <div class="ion-no-padding">
          <ion-button @click="filterButton(1)" :class="activeButton == 1 ? 'active' : ''">A-F</ion-button>
          <ion-button @click="filterButton(2)" :class="activeButton == 2 ? 'active' : ''">G-M</ion-button>
          <ion-button @click="filterButton(3)" :class="activeButton == 3 ? 'active' : ''">N-Z</ion-button>
        </div>
      </ion-toolbar>
    </ion-header>

    <ion-content class="content" :fullscreen="true">
      <div v-if="isLoading" class="load-wrap ion-text-center">
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        <p>Loading...</p>
      </div>

      <div v-if="!isLoading && Object.entries(filteredCountries).length == 0"  class="ion-padding ion-text-center">There are no countries matching your search criteria.</div>
      <ion-list class="ion-margin-top">
        <div v-for="(group, i) in Object.entries(filteredCountries).sort()" :key="i">
          <ion-item-divider>
            <ion-label class="ion-text-uppercase">{{ group[0] }}</ion-label>
          </ion-item-divider>
          <ion-item
            v-for="(country, i) in group[1].sort((a, b) => a.title > b.title ? 1 : -1)" :key="country.id"
            class="ion-no-padding"
            button
            :detail-icon="caretForwardOutline"
            :lines="i === (group[1].length - 1) ? 'none' : 'full'"
            @click="openCountry(country.id)"
          >
            <ion-label class="d-flex ion-align-items-center ion-justify-content-between ion-padding-start ion-padding-vertical">
              {{ country.title }}
              <img class="z-flag" :src="country.flag?.path" />
            </ion-label>
          </ion-item>
        </div>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
  import {mapGetters, mapActions} from 'vuex'
  import {caretForwardOutline } from 'ionicons/icons'
  import { modalController, IonList, IonItemDivider, IonSearchbar } from '@ionic/vue'
  import CataloguePinGroup from '@/plugins/app/country-list/catalogue-pin-group.vue'
  import Functions from '@/plugins/app/filter/utils/functions'

  export default {
    components: {
      IonList, IonItemDivider, IonSearchbar
    },

    mounted() {
      this.fetchCountries()
    },

    data() {
      return {
        query: '',
        activeButton: 0,
        caretForwardOutline
      }
    },

    computed: {
      ...mapGetters('countries', ['countries', 'isLoading']),

      filteredCountries() {
        let countries = this.countries

        // SEARCH BY BUTTONS
        if (this.activeButton == 1) {
          countries = countries.filter(country => {
            return Functions.normalize(country.title[0]).charCodeAt(0) < 103
          })
        } else if ( this.activeButton == 2) {
          countries = countries.filter(country => {
            return (Functions.normalize(country.title[0]).charCodeAt(0) >= 103 &&
                    Functions.normalize(country.title[0]).charCodeAt(0) <= 109)
          })
        } else if ( this.activeButton == 3) {
          countries = countries.filter(country => {
            return (Functions.normalize(country.title[0]).charCodeAt(0) >= 110 &&
                    Functions.normalize(country.title[0]).charCodeAt(0) <= 122)
          })
        }

        return countries
          .filter(country => {
            return Functions.normalize(country.title).includes(Functions.normalize(this.query)) ||
                   Functions.normalize(country.code).includes(Functions.normalize(this.query)) ||
                   Functions.normalize(country.creator).includes(Functions.normalize(this.query))
          })
          .reduce((groups, country) => {
            const firstLetter = Functions.normalize(country.title[0])

            if (!groups[firstLetter])
              return { ...groups, [firstLetter]: [country] }
            return { ...groups, [firstLetter]: [...groups[firstLetter], country] }
          }, {})
      }
    },

    watch: {
      query: function (data) {
        if (data) {
          this.activeButton = 0
        }
      }
    },

    methods: {
      ...mapActions('countries', ['fetchCountries']),

      async openCountry(countryId) {
        this.$router.push(`/app/country-detail/${countryId}`)
      },

      filterButton(buttonId) {
        this.query = ''
        if (this.activeButton == buttonId) {
          this.activeButton = 0
          return
        }
        this.activeButton = buttonId
      }
    }
  }
</script>

<style lang="sass" scoped>
h1
  margin: 0
  font-weight: bold

ion-button
  --background: #F1F1F2
  --color: black
  --border-radius: 100px
  width: 80px
  margin: 0 15px
.active
  --background: var(--default-button)
  --color: white

.white-background-color
  background: white

ion-content.content
  --padding-start: 0
  --padding-end: 0

ion-list
  padding-bottom: 60px

ion-label
  font-size: 20px
  font-weight: 700
  --ion-padding: 10px

@media only screen and (max-width: 370px)
  ion-button
    --border-radius: 100px
    width: 60px
    margin: 0 5px
</style>
